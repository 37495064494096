<template>
<div class="market">
    <div class="market-bx" style="padding-bottom: 0;">
  <div class="payList_content">
    <div class="bg_img">
      <img class="logo_bj" src="/img/Group15@2x.png" alt="">
      <img style="border-radius: 50%;" class="logo_head" :src="userInfo.imagePath ? userInfo.imagePath : headImg" alt="">
      <p class="phone">{{ userInfo.name + '&nbsp;' + verifyPhone(userInfo.mobile) }}</p>
      <el-button round icon="el-icon-arrow-left" @click="$router.push({ path: '/market', replace:true })">繼續購買</el-button>
      <!-- <div class="download">
        <div class="appButton">
          <a class="appBtn" href="https://apps.apple.com/cn/app/hunter-king-wallet/id1491881321"></a>
              <a class="appBtn" href="https://play.google.com/store/apps/details?id=com.hunter.wallet"></a>
              <a class="appBtn" href="/android/latest_release.apk"></a>
        </div>
      </div> -->
    </div>
    <div class="tab" style="padding-bottom: 0;">
      <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="全部" name="first">
      <order v-if="Order === '0'" :Order="this.Order"></order>
    </el-tab-pane>
    <el-tab-pane label="待付款" name="second"><order v-if="Order === '1'" :Order="this.Order"></order></el-tab-pane>
    <el-tab-pane label="已取消" name="third"><order v-if="Order === '2'" :Order="this.Order"></order></el-tab-pane>
    <el-tab-pane label="已支付" name="fourth"><order v-if="Order === '3'" :Order="this.Order"></order></el-tab-pane>
  </el-tabs>
    </div>
  </div>
    </div>
  </div>
</template>

<script>
import Order from './components/order.vue'
import { verifyPhone } from '@/common/common.js'
import { mapState } from 'vuex'
  export default {
    name: 'marketPayList',
    components: {
      Order
    },
    data() {
      return {
        headImg: localStorage.getItem('headImg'),
        Order: '0',
        activeName: 'first',
        verifyPhone: verifyPhone,
      };
    },
    computed: {
      ...mapState(['userInfo'])
    },
    methods: {
      handleClick(tab, event) {
        if(tab.name === 'first') {
          this.Order = '0'
        }
        if(tab.name === 'second') {
          this.Order = '1'
        }
        if(tab.name === 'third') {
          this.Order = '2'
        }
        if(tab.name === 'fourth') {
          this.Order = '3'
        }
      },
    }
  };
</script>

<style>

</style>